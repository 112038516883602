<template>
<!-- Breadcrumb  -->
  <va-card class="row align--center mx-1">
    <va-card-content> 
      <va-breadcrumbs color="primary">
          <va-breadcrumbs-item :label="$t('menu.news')" />
          <va-breadcrumbs-item  :label="$t('menu.globalNews')" />
      </va-breadcrumbs>
    </va-card-content>
  </va-card>
  <va-card class="mt-4" stripe stripe-color="#0c4898">
    <div class="flex pt-4">
      <va-button
        v-if="user_role == 'editor'"
        color="#0c4898"
        @click="formIsOpen = !formIsOpen"
        class="float-start"
      >
        <span class="material-icons"> {{ formIsOpen ? "close" : "add" }} </span>
        {{ formIsOpen ? $t("cancel") : $t("add") }}
      </va-button>
    </div>

    <va-card-content>
      <global-local-news
        :selectedRowToEdit="selectedRowToEdit"
        :formIsOpen="formIsOpen"
        @closeForm="closeForm"
        @rowInserted="insertTableRow"
        @rowUpdated="upateRow"
      />
      <div v-if="!formIsOpen" class="va-table-responsive">
        <va-inner-loading :loading="loading">
          <div v-if="globalNews.length > 0">
            <table class="va-table va-table--hoverable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t("title") }}</th>
                  <th>{{ $t("title_ar") }}</th>
                  <th>{{ $t("content") }}</th>
                  <th>{{ $t("content_ar") }}</th>
                  <th>{{ $t("publish_at") }}</th>
                  <th>{{ $t("actions") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(globalNew, index) in globalNews" :key="globalNew.id">
                  <td>{{ index + offset + 1 }}</td>
                  <td>{{ globalNew.title }}</td>
                  <td>{{ globalNew.title_ar }}</td>
                  <td
                    style="line-break: anywhere"
                    v-html="globalNew.text.slice(0, 75)"
                  ></td>
                  <td
                    style="line-break: anywhere"
                    v-html="globalNew.text_ar.slice(0, 75)"
                  ></td>
                  <td>{{ new Date(globalNew.created).toLocaleDateString() }}</td>
                  <td>
                    <div
                      v-if="user_role == 'editor'"
                      style="display: flex; justify-content: space-evenly"
                    >
                      <va-button
                        @click="showDetails(globalNew)"
                        icon="eye"
                        flat
                      />
                      <va-button
                        color="info"
                        @click="editRow(globalNew)"
                        icon="edit"
                        flat
                      />
                      <va-button
                        @click="deleteGlobalNew(globalNew.id)"
                        color="danger"
                        icon="trash"
                        flat
                      />
                    </div>
                    <div
                      v-else
                      style="display: flex; justify-content: space-evenly"
                    >
                      <va-button
                        @click="showDetails(globalNew)"
                        icon="eye"
                        flat
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <va-pagination
              v-if="pages > 1"
              color="#0a4695"
              style="direction: ltr"
              class="mt-5 float-end"
              v-model="currentPage"
              :visible-pages="3"
              :pages="pages"
            />
          </div>

          <div v-else>
            <div
              class="col align--center justify--space-evenly"
              style="height: 35vh"
            >
              <h1 style="font-size: 32px">
                {{ $t("no_globalNews") }}
                <span class="material-icons"> newspaper </span>
              </h1>
            </div>
          </div>
        </va-inner-loading>
      </div>
    </va-card-content>
  </va-card>
  <!-- Detail Modal -->
  <va-modal
    v-model="showModal"
    :title="selectedglobalNewDetailsTitle"
    hide-default-actions
    fullscreen
  >
    <div
      v-html="selectedglobalNewDetailsText"
      style="line-height: 1.9 !important"
    ></div>
  </va-modal>
</template>

<script>
import { gql, request } from "graphql-request";
import GlobalLocalNews from "@/components/forms/news/GlobalLocalNewsForm.vue";
export default {
  name: "ChampionshipFeeds",
  components: {
    GlobalLocalNews,
  },
  data() {
    return {
      globalNews: [],
      globalNewsCount: null,
      pages: null,
      currentPage: 1,
      limit: 10,
      offset: 0,
      formIsOpen: false,
      user_id: JSON.parse(localStorage["userData"])?.id,
      user_role: JSON.parse(localStorage["userData"]).user_role?.type,
      selectedRowToEdit: {},
      selectedglobalNewDetails: {},
      showModal: false,
      loading: true,
    };
  },
  async mounted() {
    const GLOBAL_NEWS_COUNT = gql`
      query {
        globalNews: GlobalLocalNewsCount(where: { news_type: "global" }) {
          count
        }
      }
    `;
    const counterResponse = await request(
      this.$store.state.appUrl,
      GLOBAL_NEWS_COUNT
    );
    this.globalNewsCount = counterResponse.globalNews.count;
    this.pages = Math.ceil(this.globalNewsCount / this.limit);
    this.getGlobalNews();
  },
  methods: {
    insertTableRow(row) {
      this.globalNews.push(row);
      this.formIsOpen = false;
    },
    async getGlobalNews() {
      this.loading = true;
      this.offset = (this.currentPage - 1) * this.limit;
      const GLOBAL_NEWS = gql`
        query ($limit: Int, $offset: Int) {
          globalNews: GlobalLocalNews(
            where: { news_type: "global" }
            limit: $limit
            offset: $offset
            order: "reverse:created"
          ) {
            title
            title_ar
            id
            path
            path_type
            news_type
            text
            text_ar
            created
          }
        }
      `;
      const response = await request(this.$store.state.appUrl, GLOBAL_NEWS, {
        limit: this.limit,
        offset: this.offset,
      });
      this.globalNews = response.globalNews;
      this.loading = false;
    },
    showDetails(globalNew) {
      this.selectedglobalNewDetails = {};
      this.selectedglobalNewDetails = Object.assign({}, globalNew);
      this.showModal = true;
    },
    closeForm() {
      this.formIsOpen = false;
      this.selectedRowToEdit = {};
    },
    upateRow(row) {
      this.getGlobalNews();
      this.selectedRowToEdit = {};
      this.formIsOpen = false;
    },
    editRow(globalNew) {
      this.selectedRowToEdit = { ...globalNew };
      this.formIsOpen = true;
    },

    async deleteGlobalNew(id) {
      this.$swal
        .fire({
          title: `${this.$t("sure?")}`,
          text: this.$t("delete_text"),
          icon: "question",
          showCancelButton: true,
          
          confirmButtonColor: "#e42222",
          confirmButtonText: this.$t("delete"),
          cancelButtonText: this.$t("cancel"),
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            const DELETE_GLOBAL_NEWS = gql`
              mutation ($id: Int!) {
                GlobalLocalNewsDelete(id: $id)
              }
            `;
            try {
              const response = await request(
                this.$store.state.appUrl,
                DELETE_GLOBAL_NEWS,
                { id }
              );
              this.loading = false;

              if (response.GlobalLocalNewsDelete) {
                this.$swal.fire({
                  icon: "success",
                  title: this.$t("delete_item_success"),
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.getGlobalNews();
              }
            } catch (error) {
              console.log(error);
              this.$swal.fire({
                icon: "warning",
                title: this.$t("delete_item_success"),
                showConfirmButton: false,
                timer: 1500,
              });
            }
          }
        });
    },
  },
  computed: {
    selectedglobalNewDetailsTitle(){
      return this.$i18n.locale == "gb"? this.selectedglobalNewDetails.title : this.selectedglobalNewDetails.title_ar 
    },
    selectedglobalNewDetailsText(){
      return this.$i18n.locale == "gb"? this.selectedglobalNewDetails.text : this.selectedglobalNewDetails.text_ar 
    },
  },
  watch: {
    currentPage() {
      this.getGlobalNews();
    },
  },
};
</script>

<style scoped>
.float-start {
  display: block;
  margin-right: auto;
  margin-left: 2.25rem;
}
body.ar .float-start {
  margin-left: auto;
  margin-right: 2.25rem;
}
</style>
