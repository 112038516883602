<template>
  <div class="mt-3" v-show="formIsOpen">
    <form @submit.prevent="formSubmit">
      <div class="mx-3 my-3">
        <va-select
          :label="$t('media_type')"
          v-model="data.path_type"
          :options="path_types"
        />
        <va-file-upload
        class="my-4 mx-3"
        type="single"
        @change="previewFile($event)"
        v-if="data.path_type == $t('vid') || data.path_type == $t('img')"
        v-model="data.file"
        :file-types="
          data.path_type == $t('img') ? '.jpg,.png' : '.mp4,.webm,.flv,.mkv'
        "
        required
        />
      <img
        v-if="!data.file || data.file?.length == 0"
        :src="$store.state.imgUrl + data.path"
        :style="{ display: data.path_type == $t('img') && data.path ? 'block' : 'none' }"
        style="width: 250px; height: 250px"
        alt="old Photo"
      />
      <img
        id="image"
        v-if="data.file?.length > 0"
        style="display: none; width: 250px; height: 250px"
        alt="new Photo"
      />
      <video
        v-if="!data.file || data.file?.length == 0"
        :style="{ display: data.path_type == $t('vid') ? 'block' : 'none' }"
        style="width: 350px; aspect-ratio: 16/9"
        :src="$store.state.imgUrl + data.path"
        controls
      ></video>
      <video
        id="video"
        v-else
        style="display: none; width: 350px; aspect-ratio: 16/9"
        controls
      ></video>
      </div>
      <va-input class="mb-4 mx-3" v-model="data.title" :label="$t('title')" />
      <va-input
        class="mb-4 mx-3"
        v-model="data.title_ar"
        :label="$t('title_ar')"
      />
      <div class="col px-3">
        <label class=" my-3" style="color:#0c4898;">{{ $t('content') }}</label>
      <v-quill
        id="content"
        toolbar="full"
        content-type="html"
        v-model:content="data.text"
        required
      />
      </div>
      <div class="col px-3">
        <label class=" my-3" style="color:#0c4898;">{{ $t('content_ar') }}</label>
        <v-quill
          id="content_ar"
          toolbar="full"
          content-type="html"
          v-model:content="data.text_ar"
          required
        />
      </div>

      <div class="card_footer">
        <va-button
          :disabled=" validateForm || loading"
          :loading="loading"
          color="success"
          class="float-end mt-4"
          type="submit"
        >
          {{ $t("save") }}
        </va-button>
      </div>
    </form>
  </div>
</template>

<script>
import { GraphQLClient, gql } from "graphql-request";
import { ref } from "vue";

export default {
  name: "global-local-news-form",
  props: ["formIsOpen", "selectedRowToEdit"],
  data() {
    return {
      data: { text: "", text_ar: "" , file:[],path_type:"" },
      path_types: [this.$t("text"), this.$t("img"), this.$t("vid")],
      news_types: [this.$t("global"), this.$t("local")],
      loading: false,
    };
  },
  emits: ["closeForm", "rowInserted", "rowUpdated"],
  computed: {
    showInput() {
      return this.data.path_type == "image" || this.data.path_type == "video";
    },
    validateForm(){
      if(
         this.data.title == '' || this.data.title_ar == '' ||
         this.data.text == '' || this.data.text_ar == ''   ||
         this.data.file?.length > 1 || this.path_type == ""  || this.news_type == ""
        )
          return true
    }
  },
  methods: {
    closeForm() {
      this.$emit("closeForm");
    },
    // Setting the Path type to "img"|"video"|"text"
    setPathType(type){
      this.data.path_type = type == "صورة" || type == "image" ? "img" :
      (type == "فيديو" || type == "video") ? "video": "text"
    },
    async formSubmit() {
      this.loading = true;
      const GLOBAL_LOCAL_NEWS_INSERT = gql`
        mutation (
          $title: String
          $title_ar: String
          $news_type: String
          $text: String
          $text_ar: String
          $path_type: String
          $path: Upload
        ) {
          query:insertGlobalLocalNews(
            insertGlobalLocalNewsInput: {
              title: $title
              title_ar: $title_ar
              news_type: $news_type
              text: $text
              text_ar: $text_ar
              path_type: $path_type
              path: $path
            }
          ) {
            msg
            status
          }
        }
      `;
      const GLOBAL_LOCAL_NEWS_UPDATE = gql`
        mutation (
          $id: Int
          $title: String
          $title_ar: String
          $news_type: String
          $text: String
          $text_ar: String
          $path_type: String
          $path: Upload
        ) {
          query:updateGlobalLocalNews(
            updateGlobalLocalNewsInput: {
              id: $id
              title: $title
              title_ar: $title_ar
              news_type: $news_type
              text: $text
              text_ar: $text_ar
              path_type: $path_type
              path: $path
            }
          ) {
            msg
            status
          }
        }
      `;
      const graphQLClient = new GraphQLClient(this.$store.state.appUrl, {
        headers: {
          // authorization: 'Bearer MY_TOKEN',
        },
      });
      this.setPathType(this.data.path_type)
      console.log(this.data.path_type);
      const variables = {
        title: this.data.title,
        title_ar: this.data.title_ar,
        text: this.data.text,
        text_ar: this.data.text_ar,
        path: this.data.file?.[0] || null ,
        path_type: this.data.path_type,
        news_type: this.$route.name == 'globalNews' ? 'global' : 'local'
      };

      let query;
      let operation;
      if (!this.data.id) {
        // Add Section
        operation = "insert";
        query = GLOBAL_LOCAL_NEWS_INSERT;
      } else {
        //edit Section
        operation = "update";
        query = GLOBAL_LOCAL_NEWS_UPDATE;
        variables.id = this.data.id;
      }
      try {
        const response = await graphQLClient.request(query, variables);
        if (response.query.status) {
          this.loading = false;
          this.$swal.fire({
            title:
              operation == "insert"
                ? this.$t("add_news_success")
                : this.$t("edit_news_success"),
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          this.loading = false;
          this.$swal.fire({
            title:
              operation == "insert"
                ? this.$t("add_news_fail")
                : this.$t("edit_news_fail"),
            text:`${response.query.msg}`,
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.data = {};
        if (this.selectedRowToEdit) {
          this.$emit("rowUpdated", data.AcademyServicesUpdate);
        } else {
          this.$emit("rowInserted", data.AcademyServicesCreate);
        }
        this.closeForm();
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    previewFile() {
      if (this.data.file?.length > 0) {
        let file = this.data.file[0];
        let blobURL = URL.createObjectURL(file);
        if (this.data.path_type == this.$t('vid')) {
          document.querySelector("#video").style.display = "block";
          document.querySelector("#video").src = blobURL;
          return;
        }
        if (this.data.path_type == this.$t('img')) {
          document.querySelector("#image").style.display = "block";
          document.querySelector("#image").src = blobURL;
          return;
        }
      } else {
        document.querySelector("#video").style.display = "none";
        document.querySelector("#image").style.display = "none";
      }
    },
  },
  watch: {
    selectedRowToEdit() {

      if (this.selectedRowToEdit) {
        this.data.id = this.selectedRowToEdit.id;
        this.data.title = this.selectedRowToEdit.title;
        this.data.title_ar = this.selectedRowToEdit.title_ar;
        this.data.path = this.selectedRowToEdit.path;
        this.data.path_type = this.selectedRowToEdit.path_type;
        this.data.news_type = this.selectedRowToEdit.news_type;
        document.querySelector("#content .ql-editor").innerHTML = this
          .selectedRowToEdit.text
          ? ref(this.selectedRowToEdit.text).value
          : "";
        document.querySelector("#content_ar .ql-editor").innerHTML = this
          .selectedRowToEdit.text_ar
          ? ref(this.selectedRowToEdit.text_ar).value
          : "";
      }
    },
  },
};
</script>

<style>
.md6 {
  display: flex;
}
.ck.ck-editor {
  margin: 19px;
}
.float-end {
  display: block;
  margin-left: auto;
  margin-right: 1rem;
}
body.ar .float-end {
  margin-right: auto;
  margin-left: 1rem;
}
</style>
